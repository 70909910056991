import { Routes, Route, Link } from "react-router-dom";import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.slim';
import "aos/dist/aos.css";
import '@fortawesome/fontawesome-free/js/all.js';
import AOS from "aos";
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Register from './pages/Register';
import Contact from './pages/Contact';
import About from './pages/About';
import Destinations from './pages/Dstinations';
import Thankyou from './pages/Thankyou';
import Privacy from './pages/Privacy';

const App = () => {
  AOS.init();

  return(<>
    <Header />
    <button className="floating-btn" onClick={() => window.open('https://wa.me/96899349290', '_system')}>
      <i className="text-white fa fab fa-whatsapp"></i>
    </button>
    <section id="home" name="home" className="scrollspy"></section>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="register/:destination" element={<Register />} />
        <Route path="contact" element={<Contact />} />
        <Route path="about" element={<About />} />
        <Route path="destinations" element={<Destinations />} />
        <Route path="thankyou/:token" element={<Thankyou />} />
        <Route path="privacy/" element={<Privacy />} />
      </Routes>
    <Footer />
  </>)
}
export default App;
