
const Header = () => {
    return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img src={require("./logo.png")} width="30" height="30" className="d-inline-block align-top" alt="" />
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation" onClick={() => document.querySelector('#navbarNavAltMarkup').classList.toggle('d-block')}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="navbar-nav">
            <a className="nav-item nav-link active" href="/">Home</a>
            <a className="nav-item nav-link" href="/destinations">Destinations</a>
            <a className="nav-item nav-link" href="/about">About</a>
            <a className="nav-item nav-link" href="/contact">Contact</a>
            <div className="nav-item nav-link col-12 text-right font-weight-bold" style={{textAlign: 'right'}}><span className="bg-selected rounded text-white p-3">Our vision is: Everything about educational tourism</span></div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;