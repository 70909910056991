import { useEffect, useState } from "react";

const Destinations = () => {
    const url = 'https://permatauk.com/api/';
    const [destinations, setDestinations] = useState([]);

    useEffect(() => {
        
        let form2 = new FormData();
        form2.append('section', 'destination-data');

        fetch(url, {
          method: 'POST',
          body: form2
        })
        .then(json => json.json())
        .then(destination => {
            console.log(destination);
            setDestinations(destination)
        })
        .catch(err => console.log(err));
    }, [])
    return(<div className="container">
        <img src="img/destination.jpg" alt="" className="w-100" />
        <div className="text-center">
            <h1 className="p-4 ml-0 mt-4">PERMATA</h1>
            <p>PERMATA Provide you with the best country destination in middle east</p>
        </div>
        <div className="mb-4 p-4"></div>

        {destinations && destinations.length > 0 && destinations.map(item => <><img src="img/oman.jpg" alt="" className="col-12 m-3" />
            <h3>{item.title}</h3>
            <p className="lead justify-content p-2">{item.description}</p>

            <p className="bg-selected rounded p-3 text-center">
                <a href={item.url} target="_blank" className="btn text-white">Learn More</a>
            </p>
            

        <div className="mb-4 p-4"></div></>)}

        
        <div className="text-center">
            <h3>Diffrent countries in your mind? Let us know and we will do the needfull</h3>
            <p></p>
            <p className="lead justify-content p-2">
                you can <a href="/contact">click here to contact us</a> and we'll be happy to help!
            </p>
            <div className="p-5"></div>
        </div>

    </div>)
}

export default Destinations;