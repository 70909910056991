import { useState } from "react";

const Contact = () => {
  const url = 'https://permatauk.com/api/';
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [msg, setMsg] = useState('');

  const handleContact = () => {
    setMsg("Sending...");
    const form = new FormData();
    form.append('section', 'contact');
    form.append('name', name);
    form.append('email', email);
    form.append('subject', subject);
    form.append('message', message);
    
    fetch(url, {
      method: 'post',
      body: form
    })
    .then(json => json.json())
    .then(res => {
      console.log(res);
      setMsg("")
      if(res.status === 'success'){
        setMsg("Your message has been sent. Thank you!");
      } else {
        setMsg("Sorry, Something went wrong, try again later...");
      }
    })
    .catch(err => setMsg("Sorry, Something went wrong, try again later..."));
  }
    return (<>
    <section id="register" name="register" className="scrollspy"></section>
        <div id="contact"></div>
        <div id="footerwrap">
          <div className="container">
            <div className="row">
              <div className="col col-lg-offset-2 centered">
                <h3>Permata</h3>
                <p>London, UK</p>
                <br />
                {/* <p><br/> Whatssapp: 99123123 <br/> Tel.: 99123123 <br/> hello@permata.uk</p> */}
              </div>
      
      
              <div className="col centered">
                <h3>Contact Us</h3>
                <br />
                <div className="contact-form php-mail-form" role="form">
      
                  <div className="form-group">
                    <input type="name" name="name" className="form-control" id="contact-name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" onChange={val => setName(val.target.value)} />
                  </div>
                  <div className="form-group">
                    <input type="email" name="email" className="form-control" id="contact-email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" onChange={val => setEmail(val.target.value)} />
                  </div>
                  <div className="form-group">
                    <input type="text" name="subject" className="form-control" id="contact-subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" onChange={val => setSubject(val.target.value)} />
                  </div>
      
                  <div className="form-group">
                    <textarea className="form-control" name="message" id="contact-message" placeholder="Your Message" rows="5" data-rule="required" data-msg="Please write something for us" onChange={val => setMessage(val.target.value)}></textarea>
                  </div>
                  <div className="p-3 m-2">{msg}</div>
                  <div className="form-send m-3">
                    <button type="submit" className="btn btn-large" onClick={handleContact}>Send Message</button>
                  </div>
      
                </div>
              </div>
      
            </div>
            <br />
            <br />
          </div>
        </div>
    </>)
}

export default Contact;