import 'bs-stepper/dist/css/bs-stepper.min.css';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Stepper from 'bs-stepper';
import * as jose from 'jose';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Register = () => {
  const url = 'https://permatauk.com/api/';
  const {destination} = useParams();
  const [step, setStep] = useState(1);
  const [fullname, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [gender, setGender] = useState('male');
  const [birthdate, setBirthdate] = useState(new Date());
  const [passport, setPassport] = useState('');
  const [passexpire, setPassexpire] = useState(new Date());
  const [disability, setDisability] = useState('');
  const [study, setStudy] = useState('');
  const [city, setCity] = useState('');
  const [start, setStart] = useState(new Date());
  const [end, setEnd] = useState(new Date());
  const [arrival, setArrival] = useState(new Date());
  const [departure, setDeparture] = useState(new Date());
  const [sprequist, setSprequist] = useState('');
  const [ticket, setTicket] = useState('1');
  const [photo, setPhoto] = useState('');
  const [acccept, setAccept] = useState(0);
  const [msg, setMsg] = useState('');
  const [img, setImg] = useState(require("../components/pp.png"));
  const [ticketImg, setTicketImg] = useState("");
  const [country, setCountry] = useState("");
  const [province, setProvince] = useState("");
  const [stateName, setStateName] = useState("");
  const [po, setPo] = useState("");
  const [postal, setPostal] = useState("");
  const [roomPrice, setRoomPrice] = useState(0);
  const [roomsList, setRoomslist] = useState([]);
  const [data, setData] = useState([]);
  const [roomType, setRoomType] = useState('');
  const [total, setTotal] = useState(0);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    let form = new FormData();
    form.append('section', 'rooms-data');
    
    let form2 = new FormData();
    form2.append('section', 'load-destination');
    form2.append('q', destination);

    fetch(url, {
      method: 'POST',
      body: form
    })
    .then(res => res.json())
    .then(res => {
        setRoomslist(res)
    })
    .catch(err => console.log(err));
    
    fetch(url, {
      method: 'POST',
      body: form2
    })
    .then(res => res.json())
    .then(res => {
      setData(res)
      setAmount(parseFloat(res[0].amount))
    })
    .catch(err => console.log(err));
    //const stepper = new Stepper(document.querySelector('.bs-stepper'))
  }, []);

  const handleStep = (stepper) => {
    //window.scrollToTop({target: 'body'})
    if(handleValidation()) {
      if(stepper === 'min'){
        setStep(temp_stem => temp_stem - 1);
      } else {
        setStep(temp_stem => temp_stem + 1);
      }
    }
  }

  useEffect(() => {
    /*window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '762739788114707',
        cookie     : true,
        xfbml      : true,
        version    : 'v14.0'
      });
        
      //window.FB.AppEvents.logPageView();   
        
    };*/
  }, [])

  const getFbInfo = () => {
    try {
      
    window.FB.login(function(response) {
      if (response.authResponse) {
        window.FB.api('/me', function(response) {
          setName(response.name)
          setEmail(response.email)
        });
        } else {
          //console.log('User cancelled login or did not fully authorize.');
        }
      });
    } catch (error) {
      
    }
  }

  useEffect(() => {
    try {
      window.google.accounts.id.initialize({
        client_id: "568867579681-deer1h0tj3uu1801jee2uees3pc01id6.apps.googleusercontent.com",
        callback: handleCredentialResponse
      });
      window.google.accounts.id.renderButton(
        document.getElementById("gbtn"),
        { theme: "outline", size: "large" }  // customization attributes
      );
      //window.google.accounts.id.prompt(); // also display the One Tap dialog
    } catch (error) {
      //console.log(error);
    }
  }, [])

  const handleCredentialResponse = (response) => {
    // decodeJwtResponse() is a custom function defined by you
    // to decode the credential response.
    const responsePayload = jose.decodeJwt(response.credential); //decodeJwtResponse(response.credential);
    setName(responsePayload.name);
    //setGender(responsePayload.gender);
    //console.log("Image URL: " + responsePayload.picture);
    setEmail(responsePayload.email);

    fetch('https://people.googleapis.com/v1/people/'+responsePayload.sub+'?personFields=birthday&key=AIzaSyCFXv-Xbrm1PS4aEn5qADh2WDmlSwULxOI&access_token='+responsePayload.email)
    .then(res => {
      //console.log(res);
    })
    //.catch(err => console.log(err))
  }

  const handelForm = () => {
    let token = Date.now();
    let form = new FormData();
    form.append('token', token);
    form.append('name', fullname);
    form.append('email', email);
    form.append('phone', phone);
    form.append('gender', gender);
    form.append('birthdate', birthdate.toLocaleDateString('en-GB'));
    form.append('passport', passport);
    form.append('passexpire', passexpire.toLocaleDateString('en-GB'));
    form.append('disability', disability);
    form.append('study', study);
    form.append('city', city);
    form.append('destination', destination);
    form.append('start', start.toLocaleDateString('en-GB'));
    form.append('end', end.toLocaleDateString('en-GB'));
    form.append('arrival', arrival.toLocaleDateString('en-GB'));
    form.append('departure', departure.toLocaleDateString('en-GB'));
    form.append('sprequist', sprequist);
    form.append('ticket', roomType);
    form.append('accoumidation', roomPrice);
    form.append('amount', amount);
    form.append('total', (parseFloat(data[0].amount) + parseFloat(roomPrice)).toFixed(1));
    form.append('photo', photo);
    form.append('accept', acccept);
    form.append('destination', destination);
    form.append('country', country);
    form.append('province', province);
    form.append('stateName', stateName);
    form.append('po', po);
    form.append('postal', postal);
    form.append('section', 'register');

    if(acccept === 0 || acccept === false) {
      setMsg('Please accept the terms and conditions');
    } else {
      setMsg('');
      fetch(url, {
        method: 'POST',
        body: form
      })
      .then(res => {
        res.json()
      })
      .then(data => {
        window.location.href = `/thankyou/${token}`;
        /*if(data.status === 'success') {
          //window.location.href = `/thankyou/${token}`;
        } else {
          setMsg('Sorry, something went wrong.. Please try again');
        }*/
      }).catch(err => {
        setMsg('Sorry, something went wrong.. Please try again');
      })
    }
  }

  const handleValidation = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    let pass = true;
    try {
      let inputs = document.getElementById('group-'+step).getElementsByClassName('required');

      if(inputs){
        for (let index = 0; index < inputs.length; index++) {
          if(inputs[index].value === ''){
            inputs[index].classList.add('border-danger');
            pass = false;
          } else {
            inputs[index].classList.remove('border-danger');
          }
        }
      }
    } catch (error) {
      //console.log(error);
    }

    
    if(step === 1){
      if(photo === ''){
        pass = false;
        setMsg('Please upload your passport picture.');
      } else {
        setMsg('');
      }
     } else if(step === 3){
      if(roomPrice < 2){
        pass = false;
        setMsg('Please choose room type...');
      } else {
        setMsg('');
      }
    }

    return pass;
  }

  const handleUpload = (e, utype) => {
    if(utype === 'photo'){
      setImg(window.URL.createObjectURL(e.target.files[0]));
      setPhoto(e.target.files[0]);
    } else {
      setTicketImg(window.URL.createObjectURL(e.target.files[0]));
      setTicket(e.target.files[0]);
    }
  }

  const getRoomPrice = (amnt, rmTyp) => {
    let startDate = new Date(arrival);
    let endDate = new Date(departure);
    let num_months = endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
    num_months = num_months === 0?1:num_months;
    let roomTotal = parseFloat(amnt) * num_months;
    if(roomTotal > 1){
      setRoomPrice(roomTotal);
    }
    setTicket(amnt);
    setRoomType(rmTyp);
    getTotal();
  }

  const getTotal = () => {
    let tot = 0;
    if(data.length > 0){
      tot = (parseFloat(data[0].amount) + parseFloat(roomPrice)).toFixed(1);
      setTotal(tot);
    }
  }

  return (<>
    <section id="register" name="register" className="scrollspy"></section>
        <div id="registerwrap2">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-lg-offset-2 m-4">
                <h1>PERMATA {data && data.length > 0 && ' - ' + data[0].name}</h1>
                <br />
                <p>You can visit this <a href="https://www.henleyglobal.com/passport-index/compare" target="_blank">website</a> to check visa availability.</p>
              </div>
            </div>
      
            <div id="stepper1" className="bs-stepper">
              <div className="bs-stepper-header" role="tablist">
                <div className="step" data-target="#first-part">
                  <div className="step-trigger" role="tab" aria-controls="first-part" id="first-part-trigger">
                    <span className={step === 1?"bs-stepper-circle bg-selected":"bs-stepper-circle"}>1</span>
                    <span className="bs-stepper-label d-none d-lg-block d-md-block d-xl-block">Personal</span>
                  </div>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#second-part">
                  <div className="step-trigger" role="tab" aria-controls="second-part" id="second-part-trigger">
                    <span className={step === 2?"bs-stepper-circle bg-selected":"bs-stepper-circle"}>2</span>
                    <span className="bs-stepper-label d-none d-lg-block d-md-block d-xl-block">Program</span>
                  </div>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#second-part">
                  <div className="step-trigger" role="tab" aria-controls="second-part" id="second-part-trigger">
                    <span className={step === 3?"bs-stepper-circle bg-selected":"bs-stepper-circle"}>3</span>
                    <span className="bs-stepper-label d-none d-lg-block d-md-block d-xl-block">Accommodation</span>
                  </div>
                </div>
                <div className="line"></div>
                <div className="step" data-target="#third-part">
                  <div className="step-trigger" role="tab" aria-controls="third-part" id="third-part-trigger">
                    <span className={step === 4?"bs-stepper-circle bg-selected":"bs-stepper-circle"}>4</span>
                    <span className="bs-stepper-label d-none d-lg-block d-md-block d-xl-block">Complete</span>
                  </div>
                </div>
              </div>

              {msg !== '' && <div className="alert alert-danger" role="alert">{msg}</div>}

              <div className="bs-stepper-content">
                <div id="first-part" className="content2" role="tabpanel" aria-labelledby="first-part-trigger">  
                  {/* {step === 1 && } */}
                  {step === 1 && <div className="form row">
                    <div className="col-lg-8 row" id="group-1">
                      <div className='border border-1 p-2 m-2 rounded  col-12'>
                        <div className='d-inline text-bold'>Grab Data From: </div>
                        <button id="gbtn" className='btn d-inline'></button>
                        <button className='btn btn-primary d-inline' onClick={getFbInfo}><i className='fa fab fa-facebook'></i> Facebook</button>
                      </div>
                        <div className='form-group m-2  col-12'>
                          <label htmlFor="name">Full Name</label>
                          <input type="text" className="form-control required" id="name" placeholder="Your Full Name" onChange={val => setName(val.target.value)} value={fullname} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="gender">Gender</label>
                          <select className="form-control" id="gender" onChange={val => setGender(val.target.value)} value={gender}>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                          </select>
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="birthdate">Birthdate</label>
                          {/* <input type="date" className="form-control required" id="birthdate" onChange={val => setBirthdate(val.target.value)} value={birthdate} /> */}
                          <DatePicker className="form-control" selected={birthdate} onChange={(date) => setBirthdate(date)} dateFormat="dd/MM/yyyy" />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="mobile">Mobile Number</label>
                          <input type="text" className="form-control required" id="mobile" placeholder="Your Mobile Number" onChange={val => setPhone(val.target.value)} value={phone} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="inputEmail1">E-mail</label>
                          <input type="email" className="form-control required" id="inputEmail1" placeholder="your@email.com" onChange={val => setEmail(val.target.value)} value={email} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="Country">Country</label>
                          <input type="text" className="form-control required" id="Country" placeholder="Country Name" onChange={val => setCountry(val.target.value)} value={country} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="Province">Province</label>
                          <input type="text" className="form-control" id="Province" placeholder="Province Name" onChange={val => setProvince(val.target.value)} value={province} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="City">City</label>
                          <input type="text" className="form-control" id="City" placeholder="City Name" onChange={val => setStateName(val.target.value)} value={stateName} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="Country">P.O Box</label>
                          <input type="text" className="form-control" id="po" placeholder="P.O Box" onChange={val => setPo(val.target.value)} value={po} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="Postal">Postal Code</label>
                          <input type="text" className="form-control" id="Postal" placeholder="Postal Code" onChange={val => setPostal(val.target.value)} value={postal} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="passportno">Passport No.</label>
                          <input type="text" className="form-control required" id="passportno" placeholder="Passport No." onChange={val => setPassport(val.target.value)} value={passport} />
                        </div>
                        <div className='form-group m-2 col-lg-5 col-md-5'>
                          <label htmlFor="passportdate">Passport Expiration Date</label>
                          {/* <input type="date" className="form-control required" id="passportdate" onChange={val => setPassexpire(val.target.value)} value={passexpire} /> */}
                          <DatePicker className="form-control" selected={passexpire} onChange={(date) => setPassexpire(date)} dateFormat="dd/MM/yyyy" />
                        </div>
                        <div className='form-group m-2'>
                          <label htmlFor="illnesses">I have the following special educational or physical needs or chronic illnesses: (Optional)</label>
                          <input type="text" className="form-control" id="illnesses" placeholder="Details" onChange={val => setDisability(val.target.value)} value={disability} />
                        </div>
                      </div>

                      <div className="col-lg-4 col-md-4">
                        <label htmlFor="photo" className='align-middle text-center'>
                          <img src={img} alt="" className='col-6 ' />
                          <input type="file" id='photo' name="photo" onChange={val => handleUpload(val, 'photo')} className="d-none" accept='images/*' />
                          <p className='text-center'>Passport Picture</p>
                        </label>
                      </div>
                    </div>}

                    {step === 2 && <div className="form">
                      <div className="col-lg-8" id="group-2">
                        <div className='form-group m-2'>
                          <label htmlFor="name">Study Name</label>
                          <input type="text" className="form-control required" id="study" placeholder="Study Program Name" onChange={val => setStudy(val.target.value)} value={study} />
                        </div>
                        <div className='form-group m-2'>
                          <label htmlFor="mobile">City Name</label>
                          <input type="text" className="form-control required" id="city" placeholder="City Name" onChange={val => setCity(val.target.value)} value={city} />
                        </div>
                        <div className='form-group m-2'>
                          <label htmlFor="start">Study start date:</label>
                          {/* <input type="date" className="form-control required" id="start" onChange={val => setStart(val.target.value)} value={start} /> */}
                          <DatePicker className="form-control" selected={start} onChange={(date) => setStart(date)} dateFormat="dd/MM/yyyy" />
                        </div>
                        <div className='form-group m-2'>
                          <label htmlFor="end">Study end date:</label>
                          {/* <input type="date" className="form-control required" id="end" onChange={val => setEnd(val.target.value)} value={end} /> */}
                          <DatePicker className="form-control" selected={end} onChange={(date) => setEnd(date)} dateFormat="dd/MM/yyyy" />
                        </div>
                      </div>
                    </div>}

                    {step === 3 && <div className="form row">
                      <div className="col-lg-8 col-md-8" id="group-3">
                        <div className='form-group m-2'>
                          <label htmlFor="arrival">Arrival Date</label>
                          {/* <input type="date" className="form-control required" id="arrival" onChange={val => setArrival(val.target.value)} value={arrival} /> */}
                          <DatePicker className="form-control" selected={arrival} onChange={(date) => {setArrival(date); setTimeout(() => {
                            getRoomPrice(ticket, roomType)
                          }, 1000);}} dateFormat="dd/MM/yyyy" />
                        </div>
                        <div className='form-group m-2'>
                          <label htmlFor="departure">Departure Date</label>
                          {/* <input type="date" className="form-control required" id="departure" onChange={val => setDeparture(val.target.value)} value={departure} /> */}
                          <DatePicker className="form-control" selected={departure} onChange={(date) => {setDeparture(date); setTimeout(() => {
                            getRoomPrice(ticket, roomType)
                          }, 1000);}} dateFormat="dd/MM/yyyy" />
                        </div>
                        <div className='form-group m-2'>
                          <label htmlFor="sprequist">Do you have any specail request?</label>
                          <input type="text" className="form-control" id="sprequist" placeholder="Details" onChange={val => setSprequist(val.target.value)} value={sprequist} />
                        </div>
                      </div>

                      {arrival != '' && departure !== '' && <div className='col-lg-4 col-md-4 rooms row p-3 text-center'>
                        <h4>Select Room Type:</h4>
                        {roomsList && roomsList.length > 0 && roomsList.map((item, index) => <label htmlFor={"room"+item.amount} className='col-6 d-inline border-info rounded'>
                          <img src={url + 'images/' + item.image} alt="" className={ticket === item.amount?'col-12 rounded border border-3 border-warning':'col-12 rounded border border-3'} />
                          <input type="radio" name="rooms" id={"room"+item.amount} className='d-none' value={item.amount} onChange={val => getRoomPrice(val.target.value, item.title)} />
                          <small>{item.title}</small>
                        </label>)}
                        <hr />
                        <h4>{roomPrice !== 0 && roomPrice !== 1 && '$' + roomPrice}</h4>

                      </div>}
                      {/* <div className="col-lg-4 col-md-4">
                        <label htmlFor="ticket" className='align-middle'>
                          <img src={ticketImg} alt="" className='col-12 text-center' />
                          <input type="file" id='ticket' name="ticket" onChange={val => handleUpload(val, 'ticket')} className="d-none" accept='images/*' />
                          <p className='text-center'>Flight Ticket Picture</p>
                        </label>
                      </div> */}
                    </div>}

                    {step === 4 && <div className="form">
                      <div className="row">
                        <div className="col-lg-8 col-md-8">
                          <h3>Terms & Conditions:</h3>
                          <p></p>
                          <ul>
                            <ol className='m-2 p-0'>Student shall adhere to the dates of attendance and departure from the institute during the period of studing, and shall not be absent from attendance and departure except with an acceptable excuse and previously submitted to the mission supervisor.</ol>

                            <ol className='m-2 p-0'>Student shall abide by the instructions and regulations of the program and the directions of the supervisor accompanying the mission.</ol>

                            <ol className='m-2 p-0'>The scholarship trainee shall adhere to the program set by the Institute during the study period.</ol>

                            <ol className='m-2 p-0'>Permata does not bear any responsibility for the student's violation of the instructions of the supervisor or the itinerary.</ol>
                          </ul>
                          <p>
                            <label htmlFor="accept">
                              <input type="checkbox" id="accept" checked={acccept} onChange={val => setAccept(val.target.checked)} /> I Aqree to Permata terms & conditions.
                            </label>
                          </p>
                        </div>

                        <div className="col-lg-4 col-md-8">
                          <ul className="list-group mb-3">
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                              <div>
                                <h6 className="my-0">Accommodation Fees</h6>
                                <small className="text-muted">Fees cover your stay</small>
                              </div>
                              <span className="text-muted">${roomPrice}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between lh-condensed">
                              <div>
                                <h6 className="my-0">Services Fees</h6>
                                <small className="text-muted">Fees cover services like guidance and transportation</small>
                              </div>
                              <span className="text-muted"> ${data && data.length > 0 && data[0].amount}</span>
                            </li>
                            <li className="list-group-item d-flex justify-content-between">
                              <span>Total (USD)</span>
                              <strong> ${data.length > 0 && (parseFloat(data[0].amount) + parseFloat(roomPrice)).toFixed(1)}</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>}

                    <div className="form-group m-2">
                      <div className="col-lg-8">
                        <br />
                        {step > 1 && <button className="btn btn-lg d-inline bg-selected text-white text-bold d-line m-2 rounded" onClick={() => handleStep('min')}>Previous</button>}
                        {step < 4 && <button className="btn btn-lg d-inline card bg-selected text-white text-bold d-line m-2 rounded" onClick={handleStep}>Next</button>}
                        {step === 4 && <button className="btn btn-lg d-inline card bg-selected text-white text-bold d-line m-2 rounded" onClick={handelForm}>Finish</button>}
                      </div>
                    </div>
                </div>
                <div id="second-part" className="content" role="tabpanel" aria-labelledby="second-part-trigger"></div>
                <div id="third-part" className="content" role="tabpanel" aria-labelledby="third-part-trigger"></div>
              </div>

            </div>


              <br />
              <br />
            </div>
        </div>
      
    </>)
}

export default Register;