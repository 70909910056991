import { useParams } from 'react-router-dom';
import { useState } from 'react';

const Thankyou = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { token } = useParams();

    return(<div className="container text-center">
        <img src={require('../components/namesign.png')} alt="" className="col-4" />
        <h1 className="p-4 ml-0 mt-4">Thank You!</h1>
        <p>We'll get back to you within 72 Hours to proccess your application, we may contact you by the phone number you entered or the email you provide during the registration process.</p>
        <p>You refrence number is: <b className='bg-selected text-white p-2 rounded'>{token}</b></p>
        <p></p>
        <div className="mb-4 p-4"></div>
    </div>)
}

export default Thankyou;