import { useEffect, useState } from "react";
import parse from 'html-react-parser';

const Home = () => {
    const [data, setData] = useState([]);
    const [destinations, setDestinations] = useState([]);
    const [faq, setFaq] = useState([]);

    useEffect(() => {
        const url = 'https://permatauk.com/api/';
        let form = new FormData();
        form.append('section', 'content-data');
        
        let form2 = new FormData();
        form2.append('section', 'destination-data');

        let faq_form = new FormData();
        faq_form.append('section', 'faq-data');

        fetch(url, {
          method: 'POST',
          body: form
        })
        .then(res => res.json())
        .then(res => {
            setData(res)
        })
        .catch(err => console.log(err));
        
        fetch(url, {
          method: 'POST',
          body: form2
        })
        .then(json => json.json())
        .then(destination => {
            setDestinations(destination)
        })
        .catch(err => console.log(err));
        
        fetch(url, {
          method: 'POST',
          body: faq_form
        })
        .then(faqs_json => faqs_json.json())
        .then(faqs => {
            setFaq(faqs)
        })
        .catch(err => console.log(err));
    }, []);

    return (<>
    <div id="headerwrap2" className="mainSection2 videbgWrapper">
        <div className="vwcontainer">
            <img src="img/inclusive.png" alt="" className="inclusive" />
            <div className="container">
                <br /><br /><br /><br /><br /><br />
                <div className="row">
                    <div className="col-lg-4" data-aos="zoom-in" >
                        <div className="phonefront2 text-center p-4">
                            <img src="img/logo.png" alt="" className="col-4 col-lg-12" />
                        </div>
                    </div>

                    <div className="col-lg-8 align-buttom text-white" data-aos="fade-in" data-aos-offset="200" >
                        <h1 className="text-uppercase">{data && data.length !== 0 && data[0].title}</h1>
                        <br />
                        <p className="white-text">
                            {data && data.length !== 0 && parse(data[0].description)}
                            <br />
                        </p>
                        {/* <p className="text-white">
                            Prepair your flight's ticket and let the learning journey begins!
                        </p> */}
                        <br />
                        <a href="/about" className="btn btn-lg btn-secondary">LEARN MORE</a>
                    </div>
                </div>
            
            </div>
        </div>
        <video className="vidbg" autoPlay loop muted>
            <source src="img/bg.mp4" type='video/mp4' />
        </video>
    </div>
    
   <section id="advanced-features">
        <div className="features-row section-bg" data-aos="fade-up">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-12 col-md-12 col-xl-6">
                        <img data-aos="fade-up" className="w-100" src="img/books.jpg" alt="" />
                    </div>
                    <div className="col-lg-6 p-4 col-sm-12 col-md-12 col-xl-6">
                        <h3 className="text-uppercase">{data && data.length !== 0 && data[1].title}</h3>
                        {data && data.length !== 0 && parse(data[1].description)}
                    </div>
                </div>

<div className="row p-5">
    <div className="col-9">
        <h3 className="p-3 m-2">{data && data.length !== 0 && data[2].title}</h3>
        {data && data.length !== 0 && parse(data[2].description)}
    </div>
    <div className="col-3">
        <img src="img/abc.jpg" className="col-12" alt="" />
    </div>
</div>
            </div>
        </div>
   </section>

    <section id="pricing" className="scrollspy"></section>
    <div className="container pricing" id="pricing" name="pricing">
        <br />
        <div className="row text-center">
            <br />
            <div className="col-lg-6 col-lg-offset-3 text-center mx-auto"  data-aos="zoom-in" >
                <h2 className="centered text-center text-uppercase">PERMATA Destinations</h2>
                <hr /><br /><br />
            </div>
        </div>

        <div className="row">
            {destinations && destinations.length > 0 && destinations.map(item => <div className="col-lg-3" data-aos="zoom-in" >
                <div className="pricing-option">
                <div className="pricing-top">
                    {item.offer === '1' && <span className="special-label">OFFER!</span>}
                    <span className="pricing-edition">{item.name}</span>
                    <span className="price">
                                    <sup>$</sup>
                                    <span className="price-amount">{item.amount}</span>
                    {/* <small>/mo</small> */}
                    </span>
                </div>
                    <img src={"img/"+item.image} alt="" className="col-12" />
                <ul></ul>
                <a href={"/register/"+item.slug} className="pricing-signup">SELECT</a>
                </div>
            </div>)}
        </div>
        <br />
        <br />
    </div>

    <div id="section02" className="mt-5">
        <div className="container"  data-aos="fade-in" data-aos-easing="ease-in-sine">
            <h1>Check your visa availability <br /> to your selected destination.</h1>
            <br />
            <a href="https://www.henleyglobal.com/passport-index/compare" target="_blank" className="btn btn-secondary btn-lg">CHECK</a>
        </div>
    </div>

    <section className="container mt-5">
    <h3 className="text-center mb-4 pb-2 primary-text fw-bold">Frequency Asked Questions</h3>
    <p className="text-center mb-5">
        Find the answers for the most frequently asked questions below
    </p>

    <div className="row">
        {faq && faq.length > 0 && faq.map(item => <div className="col-md-6 col-lg-4 mb-4" data-aos="zoom-in" data-aos-easing="ease-in-sine">
            <h6 className="mb-3 primary-text" data-aos="fade-in" data-aos-easing="ease-in-sine"><i className="far fa-circle-question primary-text pe-2"></i> {item.title}</h6>
            <p data-aos="fade-in" data-aos-easing="ease-in-sine">{parse(item.description)}</p>
        </div>)}
    </div>
    </section>

  </>)
}

export default Home;