
const Footer = () => {
    return(<div id="copyrights">
    <div className="container">
      <div className="m-4">
        <a href="https://instagram.com/permatauk" title="Instagram" className="p-4 h3" target="_blank"><i className="fa fab fa-instagram"></i></a>
        <a href="https://twitter.com/permatauk" title="Twitter" className="p-4 h3" target="_blank"><i className="fa fab fa-twitter"></i></a>
        <a href="https://facebook.com/permatauk" title="Facebook" className="p-4 h3" target="_blank"><i className="fa fab fa-facebook"></i></a>
        <a href="https://snapchat.com/add/permatauk" title="Snapchat" className="p-4 h3" target="_blank"><i className="fa fab fa-snapchat"></i></a>
        <a href="https://ticktok.com/@permatauk" title="Tiktok" className="p-4 h3" target="_blank"><i className="fa fab fa-tiktok"></i></a>
        <br /><br /><br />
        <p>
          <small className="text-white">
            Company Number 14152755 <br />
            The Registrar of Companies for England and Wales, declared PERMATA GLOBAL ROUTE LTD is under the Companies Act 2006 as a private company, the company is limited by shares, and the situation of its registered office is in England
            and Wales.
          </small>
        </p>
      </div>
      <p>
        <a href="/about">About</a> | <a href="/privacy">Privacy Policy</a>
      </p>
      <p>
        &copy; Copyrights <strong>PERMATA</strong>. All Rights Reserved
      </p>
      {/* <div className="credits">
        Developed by <a href=""></a>
      </div> */}
    </div>
  </div>)
}

export default Footer;