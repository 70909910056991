import { useEffect, useState } from "react";
import parse from 'html-react-parser';

const About = () => {
    const url = 'https://permatauk.com/api/';
    const [about_txt, setAboutTxt] = useState('')
    const [about_title, setAboutTitle] = useState('')

    useEffect(() => {
        
        let form = new FormData();
        form.append('section', 'content-data');
        
        fetch(url, {
          method: 'POST',
          body: form
        })
        .then(res => res.json())
        .then(res => {
            console.log(res);
            setAboutTxt(res[3].description)
            setAboutTitle(res[3].title)
        })
        .catch(err => console.log(err));
    }, [])
    return(<div className="container">
        <img src="img/about.jpg" alt="" className="w-100" />
        <h1 className="p-4 ml-0 mt-4">{about_title}</h1>
        {parse(about_txt)}
        <div className="mb-4 p-4"></div>
    </div>)
}

export default About;